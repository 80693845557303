<template>
    <div class="view-root statistics">
      <div class="statistics-title">本次会议数据</div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card>
            <el-statistic :value="dataList.inquiries" title="总咨询人数"></el-statistic>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <el-statistic :value="dataList.messages" title="总咨询消息数"></el-statistic>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <el-statistic :value="dataList.customers" title="有人咨询的品牌客户数"></el-statistic>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <el-statistic :value="dataList.agenda" title="有人咨询的议程数"></el-statistic>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </template>
<script>
import {
    staticsMessage
} from '../../api/api'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: {
                inquiries:0,  //总咨询人数
                messages:0,    //总咨询消息数
                customers:0,  //品牌客户数
                agenda:0      //议程数
            }   
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            staticsMessage(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
    }

})
</script>
<style scoped lang="scss">
.statistics{
  width: 100%;
  padding: 30px;
  &-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}
:deep(.el-card){
  margin-bottom: 20px;
}
:deep(.el-statistic) {
  .head{
    font-size: 24px;
    margin-bottom: 16px;
  }
  .con {
    .number {
      font-size: 36px;
      font-weight: bold;
    }
  }
}
</style>